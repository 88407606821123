<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑配置' : '新增配置'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 配置名称 -->
      <a-form-item
        label="配置名称"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
        />
      </a-form-item>
      <!-- 配置类型 -->
      <a-form-item
        label="配置类型"
        name="type"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.type"
          placeholder="请选择"
          @change="changeConfigType"
        >
          <a-select-option
            v-for="item in ConfigTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 支付渠道 -->
      <a-form-item
        v-if="formState.type && ![5].includes(formState.type)"
        label="支付渠道"
        name="pay_channel"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.pay_channel"
          placeholder="请选择"
          @change="changePayChannel"
        >
          <a-select-option
            v-for="item in PayChannels"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 配置信息 -->
      <template v-for="conf in confs" :key="conf.key">
        <a-form-item
          v-if="!conf.pay_channel || conf.pay_channel.includes(formState.pay_channel)"
          :label="conf.title"
          :name="['app_setting', conf.key]"
          :rules="[{ required: conf.required }]"
        >
          <a-textarea
            v-if="conf.input === 'textarea'"
            placeholder="请输入"
            v-model:value="formState.app_setting[conf.key]"
          />
          <a-input
            v-else
            placeholder="请输入"
            v-model:value="formState.app_setting[conf.key]"
          />
        </a-form-item>
      </template>
      <!-- 备注 -->
      <a-form-item
        label="备注"
        name="remark"
      >
        <a-textarea
          placeholder="请输入"
          v-model:value="formState.remark"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { ConfigTypes, PayChannels } from '@/utils/constantList'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { projectConfigSave, projectConfigDetail } from '@/api/system'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 配置数组
let confs = ref([])
// 是否为复制创建
let isCopy = ref(false)
// 表单
let formState = reactive({
  // 配置名称
  name: undefined,
  // 配置类型
  type: undefined,
  // 支付渠道
  pay_channel: undefined,
  // 配置信息
  app_setting: {},
  // 备注
  remark: undefined
})
// 配置数据
let confJson = {
  // 支付宝
  1: [
    {
      key: 'app_id',
      title: 'AppID',
      default: undefined,
      required: true
      // 输入框形式：textarea（多行）、不传默认（input）
      // input: 'textarea'
    },
    {
      key: 'private_key',
      title: '私钥',
      default: undefined,
      input: 'textarea',
      required: true
    },
    {
      key: 'alipay_public_key',
      title: '公钥',
      default: undefined,
      input: 'textarea',
      required: true
    },
    {
      key: 'notify_url',
      title: '回调地址',
      default: undefined,
      required: true
    }
  ],
  // 微信
  2: [
    {
      key: 'app_id',
      title: 'AppID',
      default: undefined,
      required: true,
      pay_channel: [1, 4, 7, 10]
    },
    {
      key: 'app_secret',
      title: 'AppSecret',
      default: undefined,
      required: true,
      pay_channel: [1, 4, 7, 10]
    },
    {
      key: 'offer_id',
      title: 'OfferID',
      default: undefined,
      required: true,
      pay_channel: [10]
    },
    {
      key: 'app_key',
      title: 'AppKey',
      default: undefined,
      required: true,
      pay_channel: [10]
    },
    {
      key: 'token',
      title: 'Token',
      default: undefined,
      required: true,
      pay_channel: [10]
    },
    {
      key: 'encoding_aes_key',
      title: 'EnAESKey',
      default: undefined,
      required: true,
      pay_channel: [10]
    },
    {
      key: 'mch_id',
      title: '商户ID',
      default: undefined,
      required: true,
      pay_channel: [1, 4, 7]
    },
    {
      key: 'key',
      title: '密钥',
      default: undefined,
      required: true,
      pay_channel: [1, 4, 7]
    },
    {
      key: 'notify_url',
      title: '回调地址',
      default: undefined,
      required: true,
      pay_channel: [1, 4, 7]
    },
    {
      key: 'apiclient_cert',
      title: 'apiclient_cert',
      default: undefined,
      required: false,
      pay_channel: [1, 4, 7]
    },
    {
      key: 'apiclient_key',
      title: 'apiclient_key',
      default: undefined,
      required: false,
      pay_channel: [1, 4, 7]
    }
  ],
  // 抖音
  3: [
    {
      key: 'app_id',
      title: 'AppID',
      default: undefined,
      required: true,
      pay_channel: [8, 11, 12]
    },
    {
      key: 'app_secret',
      title: 'AppSecret',
      default: undefined,
      required: true,
      pay_channel: [8, 11, 12]
    },
    {
      key: 'salt',
      title: 'SALT',
      default: undefined,
      required: true,
      pay_channel: [8, 11, 12]
    },
    {
      key: 'token',
      title: 'Token令牌',
      default: undefined,
      required: true,
      pay_channel: [8, 11, 12]
    },
    {
      key: 'notify_url',
      title: '回调地址',
      default: undefined,
      required: true,
      pay_channel: [8, 11, 12]
    },
    {
      key: 'key_version',
      title: '公钥版本',
      default: undefined,
      required: true,
      pay_channel: [11, 12]
    },
    {
      key: 'private_key',
      title: '应用私钥',
      default: undefined,
      input: 'textarea',
      required: true,
      pay_channel: [11, 12]
    },
    {
      key: 'public_key',
      title: '平台公钥',
      default: undefined,
      input: 'textarea',
      required: true,
      pay_channel: [11, 12]
    }
  ],
  // 快手
  4: [
    {
      key: 'app_id',
      title: 'AppID',
      default: undefined,
      required: true
    },
    {
      key: 'app_secret',
      title: 'AppSecret',
      default: undefined,
      required: true
    },
    {
      key: 'refund_notify_url',
      title: '退款回调',
      default: undefined,
      required: true,
      pay_channel: [13]
    },
    {
      key: 'notify_url',
      title: '回调地址',
      default: undefined,
      required: true
    }
  ],
  // 企业微信
  5: [
    {
      key: 'corp_id',
      title: '企业CorpID',
      default: undefined,
      required: true
    },
    {
      key: 'secret',
      title: '应用密钥',
      default: undefined,
      required: true
    },
    {
      key: 'kf_secret',
      title: '客服密钥',
      default: undefined,
      required: true
    },
    {
      key: 'token',
      title: '签名Token',
      default: undefined,
      required: true
    },
    {
      key: 'encoding_aes_key',
      title: '签名密钥',
      default: undefined,
      required: true
    }
  ],
  // 杉德
  6: [
    {
      key: 'mch_id',
      title: '商户ID',
      default: undefined,
      required: true
    },
    {
      key: 'key',
      title: '商户Key',
      default: undefined,
      required: true
    },
    {
      key: 'notify_url',
      title: '回调地址',
      default: undefined,
      required: true
    },
    {
      key: 'redirect_url',
      title: '回跳链接',
      default: undefined,
      required: true
    }
  ]
}

// 打开抽屉
function showDrawer (params, copy = false) {
  // id
  id.value = params.id
  // 是否为复制创建
  isCopy.value = copy
  // 展开
  visible.value = true
  // 获取详情
  if (id.value) { getProjectConfigDetail() }
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    confs.value = []
    visible.value = false
  }
}

// 切换配置类型
function changeConfigType (e) {
  // 清空配置
  formState.app_setting = {}
  formState.pay_channel = undefined
  // 获取配置列表
  confs.value = confJson[e]
  // 配置默认值
  confs.value.forEach(conf => {
    formState.app_setting[conf.key] = conf.default
  })
}

// 切换支付渠道
function changePayChannel () {
  // getProjectConfigDetail()
}

// 获取详情
function getProjectConfigDetail () {
  projectConfigDetail({ id: id.value }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      changeConfigType(data.type)
      formState.name = data.name
      formState.type = data.type
      formState.pay_channel = (data.pay_channel > 0) ? data.pay_channel : undefined
      formState.remark = data.remark
      formState.app_setting = data.app_setting
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      id: isCopy.value ? undefined : id.value,
      ...formState
    }
    projectConfigSave(params).then(res => {
      isLoading.value = false
      const { code, msg } = res
      if (code === 0) {
        if (id.value) {
          message.success('修改成功')
        } else {
          message.success('新增成功')
        }
        emit('success')
        onClose()
      } else {
        message.error(res.message || msg)
      }
    }).catch(() => {
      isLoading.value = false
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>